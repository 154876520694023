<template>
  <div class="layer-switch-mini">
    <el-switch
        class="switch layer-switch-mini__switch"
        v-model="switcher"
        active-text="Объекты"
        inactive-text="Партнеры"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import fetchEntities from '@/helpers/FetchEntities';

export default {
  name: 'LayerSwitchMini',
  data() {
    return {
      switcher: null
    }
  },
  watch: {
    async layer(newValue, oldValue) {
      if (newValue === oldValue || this.searchQuery) {
        return;
      }

      this.switcher = newValue === 'objects';
    },
    switcher(newValue, oldValue) {
      if (newValue) {
        this.setLayer('objects');
      } else {
        this.setLayer('partners');
      }
    }
  },
  methods: {
    ...mapActions('loader', ['startLoading', 'stopLoading']),
    ...mapActions('layer', ['setLayer']),
    ...mapActions('entity', ['setEntities'])
  },
  computed: {
    ...mapGetters('layer', ['layer']),
    ...mapGetters('map', ['bounds']),
    ...mapGetters('search', ['searchQuery'])
  }
}
</script>

<style lang="scss">
.layer-switch-mini {
  position: fixed;
  top: 30px;
  right: 40px;

  & &__switch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 255px;
    height: 55px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    background-color: #fff;
  }

  .el-switch__label {
    font-style: normal;
  }

  .el-switch__label > span {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.5;
  }

  .el-switch__label.is-active > span {
    opacity: 1;
  }

  .el-switch__core {
    width: 47px!important;
    height: 27px;
    border-radius: 32px;

    &::after {
      width: 19px;
      height: 19px;
      top: 3px;
      left: 3px;
    }
  }

  .el-switch.is-checked .el-switch__core::after {
    margin-left: -22px;
  }

  @media (max-width: 1023px) {
    top: 190px;
    right: 10px;

    & &__switch {
      width: 184px;
      height: 39px;
    }
    .el-switch__label > span {
      font-size: 12px;
      line-height: 14px;
    }

    .el-switch__label--left {
      margin-right: 8px;
    }

    .el-switch__label--right {
      margin-left: 8px;
    }

    .el-switch__core {
    width: 34px!important;
    height: 20px;
    border-radius: 32px;

    &::after {
      width: 14px;
      height: 14px;
      top: 2px;
      left: 2px;
    }
  }

  .el-switch.is-checked .el-switch__core::after {
    margin-left: -16px;
  }
  }

  @media (max-height: 499px) {
    top: 125px;
  }
}
</style>
