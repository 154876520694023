import api from '@/requests';
import store from '@/store';

// Функция для получения области координат
function getCoordinateArea(bounds) {
    const [[minLat, minLng], [maxLat, maxLng]] = bounds;
    return {
        minLat,
        minLng,
        maxLat,
        maxLng
    };
}

// Функция для получения сущностей
async function fetchEntities(layer, bounds, setEntities, signal) {
    if (bounds) {
        setEntities([]);
    }

    const coordinateArea = getCoordinateArea(bounds);

    try {
        if (layer === 'partners') {
            const items = await api.partners.listByCoordinateArea(coordinateArea, signal);

            if (!signal || !signal.aborted) {
                setEntities(items);
            } else {
                console.warn('Запрос был отменён');
            }
        } else if (layer === 'objects') {
            // TODO: Реализовать запрос объектов по координатам
            setEntities([]);
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.warn('Запрос был отменён');
        } else {
            console.error('Произошла ошибка', error);
        }
    }
}

// Функция для фильтрации сущностей
async function fetchEntitiesFilter(layer, bounds, filter, actions, signal) {
    const { setEntities, setFilterId, setCount } = actions;
    const coordinateArea = getCoordinateArea(bounds);

    try {
        if (layer === 'partners') {
            const data = await api.partners.filter(coordinateArea, filter, signal);

            if (!signal || !signal.aborted) {
                setEntities(data.items);
                setFilterId(data.meta.id);
                setCount(data.meta.count);
            } else {
                console.warn('Запрос был отменён');
            }
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.warn('Запрос был отменён');
        } else {
            console.error('Произошла ошибка', error);
        }
    }
}

async function fetchEntityFilterById(layer, id, bounds, actions, signal) {
    const {
        setEntities,
        setFilterId,
        selectDirection,
        setCount
    } = actions;
    const coordinateArea = getCoordinateArea(bounds);

    try {
        if (layer === 'partners') {
            const data = await api.partners.filterById(id, coordinateArea, signal);

            if (!signal || !signal.aborted) {
                setFilterId(data.meta.id);
                setCount(data.meta.count);
                selectDirection(data.meta.filters.directions);
                store.dispatch('filter/selectPartnerType', data.meta.filters.partnerTypes ?? []);
                store.dispatch('filter/selectPartnerAttribute', data.meta.filters.partnerAttributes ?? []);
                store.dispatch('filter/selectProduct', data.meta.filters.products ?? []);
                setEntities(data.items);
            } else {
                console.warn('Запрос был отменён');
            }
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.warn('Запрос был отменён');
        } else {
            console.error('Произошла ошибка', error);
        }
    }
}

const entity = {
    list: fetchEntities,
    filter: fetchEntitiesFilter,
    filterById: fetchEntityFilterById
};

export default entity;
