const state = {
    id: null,
    count: 0,
    directions: [],
    partnerTypes: [],
    partnerAttributes: [],
    productGroups: [],
    selectedDirections: [],
    selectedPartnerTypes: [],
    selectedPartnerAttributes: [],
    selectedProducts: []
};

const actions = {
    setFilterId({ commit }, id) {
        commit('SET_ID', id);
    },
    setCount({ commit }, count) {
        commit('SET_COUNT', count);
    },
    setDirections({ commit }, directions) {
        commit('SET_DIRECTIONS', directions);
    },
    setPartnerTypes({ commit }, partnersTypes) {
        commit('SET_PARTNER_TYPES', partnersTypes);
    },
    setPartnerAttributes({ commit }, partnerAttributes) {
        commit('SET_PARTNER_ATTRIBUTES', partnerAttributes);
    },
    setProductGroups({ commit }, productGroups) {
        commit('SET_PRODUCT_GROUPS', productGroups);
    },
    selectDirection({ commit }, directions) {
        commit('SELECT_DIRECTION', directions);
    },
    selectPartnerType({ commit }, items) {
        commit('SELECT_PARTNER_TYPE', items);
    },
    selectPartnerAttribute({ commit }, items) {
        commit('SELECT_PARTNER_ATTRIBUTE', items);
    },
    selectProduct({ commit }, items) {
        commit('SELECT_PRODUCT', items);
    },
    clearFilter({ commit }) {
        commit('SET_ID', null);
        commit('SELECT_DIRECTION', []);
        commit('SELECT_PARTNER_TYPE', []);
        commit('SELECT_PARTNER_ATTRIBUTE', []);
        commit('SELECT_PRODUCT', []);
        commit('SET_COUNT', 0);
    }
};

const mutations = {
    SET_ID(state, id) {
        state.id = id;
    },
    SET_COUNT(state, count) {
        state.count = count;
    },
    SET_DIRECTIONS(state, items) {
        state.directions = items;
    },
    SET_PARTNER_TYPES(state, items) {
        state.partnerTypes = items;
    },
    SET_PARTNER_ATTRIBUTES(state, items) {
        state.partnerAttributes = items;
    },
    SET_PRODUCT_GROUPS(state, items) {
        state.productGroups = items;
    },
    SELECT_DIRECTION(state, items) {
        state.selectedDirections = items;
    },
    SELECT_PARTNER_TYPE(state, items) {
        state.selectedPartnerTypes = items;
    },
    SELECT_PARTNER_ATTRIBUTE(state, items) {
        state.selectedPartnerAttributes = items;
    },
    SELECT_PRODUCT(state, items) {
        state.selectedProducts = items;
    }
};

const getters = {
    filterId: state => state.id,
    filterCount: state => state.count,
    filterValues: state => ({
        directions: state.selectedDirections.length ? state.selectedDirections.sort() : null,
        partnerTypes: state.selectedPartnerTypes.length ? state.selectedPartnerTypes.sort() : null,
        partnerAttributes: state.selectedPartnerAttributes.length ? state.selectedPartnerAttributes.sort() : null,
        products: state.selectedProducts.length ? state.selectedProducts.sort() : null
    }),
    directions: state => state.directions,
    partnerTypes: state => state.partnerTypes,
    partnerAttributes: state => state.partnerAttributes,
    productGroups: state => state.productGroups,
    selectedDirections: state => state.selectedDirections,
    selectedPartnerTypes: state => state.selectedPartnerTypes,
    selectedPartnerAttributes: state => state.selectedPartnerAttributes,
    selectedProducts: state => state.selectedProducts
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
