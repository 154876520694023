const state = {
    value: ''
};

const actions = {
    setLayer({ commit }, layer) {
        commit('SET_LAYER', layer);
    }
};

const mutations = {
    SET_LAYER(state, layer) {
        state.value = layer;
    }
};

const getters = {
    layer: state => state.value
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
