<template>
  <div class="modal">
    <el-dialog visible @close="closeModal" class="modal-switch">
      <h4 class="modal__header">
        Переключение между слоями<br>«Партнеры/Объекты»
      </h4>
      <div class="modal__content">
        <div class="modal-box">
          <img src="@/assets/images/modal-map.png" alt="" class="modal-map">
        </div>
        <div class="modal__text">
          <p>
            Переключайтесь между слоями
          </p>
          <p>«Партнеры/Объекты»,
            чтобы осуществить поиск<br>на&nbsp;нужном слое.
          </p>
        </div>
      </div>
      <div class="modal__footer">
        <button class="btn btn-green modal-btn" @click="closeModal">
          Понятно
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LayerSwitch',
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.modal__header {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 20px;
}

.modal-box {
  position: relative;
  margin-bottom: 20px;
  height: 115px;
}

.modal-map {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal__text {
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 20px;
}

.modal-btn {
  width: 100%;
}

@media (max-width: 480px) {
  .modal__header {
    font-size: 20px;
    line-height: 24px;
  }

  .modal__text {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
