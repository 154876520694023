import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/partners',
    component: () => import('@/components/sidebar/SidebarIndex.vue')
  },
  {
    path: '/partners/search',
    component: () => import('@/components/sidebar/SidebarSearchResult.vue')
  },
  {
    path: '/partners/filters',
    component: () => import('@/components/sidebar/SidebarFilters.vue')
  },
  {
    path: '/partners/filter/results',
    component: () => import('@/components/sidebar/SidebarResultsList.vue')
  },
  // {
  //   /**
  //   * @param {string} [directionIDs] - encoded destination identifiers of partners
  //   * @param {string} [filterIDs] - encoded Filter IDs
  //   * @param {boolean} [attributes] - attributes of a partner
  //   * @param {boolean | undefined} [certificate] - availability of a partner certificate
  //   */
  //   path: '/partners/results/:directionIDs/:filterIDs/:attributes/:certificate',
  //   component: () => import('@/components/SearchResults.vue'),
  //   meta: {
  //     hasScroll: false
  //   }
  // },
  // {
  //   /**
  //   * @param {string} [directionIDs] - encoded destination identifiers of partners
  //   * @param {string | boolean} [filterIDsAttributes] - encoded Filter IDs or attributes of a partner
  //   * @param {boolean} [attributesCertificate] - attributes of a partner or availability of a partner certificate
  //   */
  //   path: '/partners/results/:directionIDs/:filterIDsAttributes/:attributesCertificate',
  //   component: () => import('@/components/SearchResults.vue'),
  //   meta: {
  //     hasScroll: false
  //   }
  // },
  // {
  //   /**
  //   * @param {string} [directionIDs] - encoded destination identifiers of partners
  //   * @param {boolean} [attributes] - attributes of a partner
  //   */
  //   path: '/partners/results/:directionIDs/:attributes',
  //   component: () => import('@/components/SearchResults.vue'),
  //   meta: {
  //     hasScroll: false
  //   }
  // },
  // {
  //   /**
  //   * @param {string} [directionIDs] - encoded destination identifiers of partners
  //   * @param {string} [filterIDs] - encoded Filter IDs
  //   * @param {string} [range] - object year range
  //   */
  //   path: '/objects/results/:directionIDs/:filterIDs/:range',
  //   component: () => import('@/components/SearchResults.vue'),
  //   meta: {
  //     hasScroll: false
  //   }
  // },
  // {
  //   /**
  //   * @param {string} [directionIDs] - encoded destination identifiers of partners
  //   * @param {string} [filterIDsRange] - encoded filter IDs or feature year range
  //   */
  //   path: '/objects/results/:directionIDs/:filterIDsRange',
  //   component: () => import('@/components/SearchResults.vue'),
  //   meta: {
  //     hasScroll: false
  //   }
  // },
  {
    /**
    * @param {string} [directionIDs] - encoded destination identifiers of partners
    */
    path: '/objects/results/:directionIDs',
    component: () => import('@/components/SearchResults.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    path: '/objects/filters',
    component: () => import('@/components/FacilityFilters.vue')
  },
  // {
  //   path: '/partners/filters',
  //   component: () => import('@/components/PartnerFilters.vue')
  // },
  {
    path: '/objects/:id',
    component: () => import('@/components/FacilityCard.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    path: '/partners/:id',
    component: () => import('@/components/PartnerCard.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    path: '/objects',
    component: () => import('@/components/DefaultState.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Глобальный обработчик для сброса фильтра
router.beforeEach((to, from, next) => {
  if ((to.path !== '/partners/filters' && to.path !== '/objects/filters') && (to.path !== '/partners/filter/results' && to.path !== '/objects/filter/results')) {
      store.dispatch('filter/clearFilter'); // Вызов действия сброса фильтра в Store
  }
  next(); // Переход на новый маршрут
});

export default router;
