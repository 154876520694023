<template>
    <div class="sidebar-search">
        <div class="search-wrapper search">
            <form @submit.prevent="search" class="input">
                <div class="search-box">
                    <div class="search-icon-wrapper">
                        <img class="search-icon cursor-pointer" :class="{ 'filtered' : query }" src="@/assets/images/svg/search.svg" alt="Поиск" @click="search" />
                    </div>
                    <input type="text" v-model="query" class="search-input" :placeholder="placeholder">
                </div>
                <div v-if="query" class="icon cursor-pointer" @click="clear">
                    <img src="@/assets/images/svg/close.svg" alt="Очистить">
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex';
import entity from '@/helpers/FetchEntities';

export default {
    name: 'SidebarSearch',
    props: {
        placeholder: {
            type: String,
            default: 'Поиск'
        }
    },
    data() {
        return {
            query: ''
        }
    },
    watch: {
        searchQuery(newValue, oldValue) {
            if (newValue !== oldValue && newValue !== this.query) {
                this.query = newValue;
            }
        }
    },
    methods: {
        async search() {
            if (this.query.length < 3) {
                return;
            }

            if (this.query !== this.searchQuery) {
                this.$router.push({ path: '/partners/search', query: { q: this.query } });
            }
        },
        async clear() {
            this.query = '';
            this.clearQuery();
            if (this.$route.fullPath !== '/') {
                this.$router.push(`/${this.layer}`);
            }

            this.startLoading();
            await entity.list(this.layer, this.bounds, this.setEntities);
            this.stopLoading();
        },
        ...mapActions('search', ['clearQuery']),
        ...mapActions('entity', ['setEntities']),
        ...mapActions('loader', ['startLoading', 'stopLoading'])
    },
    computed: {
        ...mapGetters('search', ['searchQuery']),
        ...mapGetters('layer', ['layer']),
        ...mapGetters('map', ['bounds'])
    }
}
</script>

<style>
.filtered {
    filter: invert(9%) sepia(100%) saturate(5881%) hue-rotate(327deg) brightness(102%) contrast(101%)
}

@media (max-width: 1023px) {
    .search-wrapper {
        margin-bottom: 10px;
    }
}
</style>
