<template>
    <div class="sidebar-top">
        <img src="@/assets/images/svg/logo.svg" alt="РЕХАУ" class="logo" @click="handleLogoClick">
        <div class="location-block">
          <span class="caption">Выбрать город</span>
          <span class="city" @click="showLocationSelectionPopup">{{ userLocationName }}</span>
        </div>
    </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SidebarHeader',
  async mounted() {
    let isRegionDefined = await this.defineRegion();
    let retryCount = 0;
    const maxRetries = 5;

    if (!isRegionDefined) {
      const retry = setInterval(async () => {
        isRegionDefined = await this.defineRegion();
        retryCount++;

        if (isRegionDefined || retryCount >= maxRetries) {
          clearInterval(retry);
          if (!isRegionDefined) {
            console.error('Не удалось определить регион после нескольких попыток.');
          }
        }
      }, 3000);
    }
  },
  methods: {
    // Обработчик клика по логотипу
    handleLogoClick() {
        if (this.$route.fullPath !== '/') {
            this.$router.push(`/${this.layer}`);
        }
    },
    // Определение региона пользователя
    async defineRegion() {
      try {
        if (!window.ymaps) {
          throw new Error('Yandex Maps API не загружен');
        }
        const geocode = await window.ymaps.geocode(this.userLocation, { results: 1, kind: 'locality' });
        const [geoObject] = geocode.geoObjects.toArray(); // Получаем массив объектов и деструктурируем
        if (!geoObject) {
          throw new Error('Не удалось определить местоположение');
        }

        const [administrativeArea] = geoObject.getAdministrativeAreas(); // Деструктурируем результат
        if (!administrativeArea) {
          throw new Error('Административная область не найдена');
        }

        this.setUserLocationName(administrativeArea);
        return true;
      } catch (error) {
        console.error(error.message); // Логируем полный объект ошибки
        return false;
      }
    },
    ...mapActions('location', ['setUserLocationName', 'showLocationSelectionPopup'])
  },
  computed: {
    ...mapGetters('location', ['userLocation', 'userLocationName']),
    ...mapGetters('layer', ['layer'])
  }
}
</script>

<style scoped lang="scss">
.location-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .caption {
    font-size: 18px;
    color: #8C8C8C;
    user-select: none;
  }
}
</style>
