const state = {
    userLocation: null,
    userLocationName: '',
    currentLocation: null,
    isShowLocationSelectionPopup: false
};

const actions = {
    setLocation({ commit }, { coords, zoom }) {
        commit('SET_USER_LOCATION', coords);
        commit('SET_CURRENT_LOCATION', { coords, zoom: zoom || 10 });
    },
    setUserLocation({ commit }, coords) {
        commit('SET_USER_LOCATION', coords);
    },
    setUserLocationName({ commit }, name) {
        commit('SET_USER_LOCATION_NAME', name);
    },
    setCurrentLocation({ commit }, { coords, zoom }) {
        commit('SET_CURRENT_LOCATION', { coords, zoom: zoom || 10 });
    },
    showLocationSelectionPopup({ commit }) {
        commit('SET_SHOW_LOCATION_SELECTION_POPUP', true);
    },
    hideLocationSelectionPopup({ commit }) {
        commit('SET_SHOW_LOCATION_SELECTION_POPUP', false);
    }
};

const mutations = {
    SET_USER_LOCATION(state, coords) {
        state.userLocation = coords;
    },
    SET_USER_LOCATION_NAME(state, name) {
        state.userLocationName = name;
    },
    SET_CURRENT_LOCATION(state, location) {
        state.currentLocation = location;
    },
    SET_SHOW_LOCATION_SELECTION_POPUP(state, isShow) {
        state.isShowLocationSelectionPopup = isShow;
    }
};

const getters = {
    userLocation: state => state.userLocation,
    userLocationName: state => state.userLocationName,
    currentLocation: state => state.currentLocation,
    isShowLocationSelectionPopup: state => state.isShowLocationSelectionPopup
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
