<template>
  <div class="modal">
    <el-dialog visible @close="closeModal" title="Город или регион" class="location-modal">
      <form>
        <el-select
          ref="input"
          filterable
          v-model="chosenRegion"
          class="location-dropdown"
          no-match-text="Регион не найден"
          remote
          :remote-method="getSuggests"
          popper-class="location-choice"
          placeholder="Город, регион или страна">
          <el-option
            v-for="region in regions"
            :key="region.name + region.desc + Math.random()"
            :value="region.id"
            :label="`${region.name}${region.desc ? ', ' + region.desc : ''}`"></el-option>
        </el-select>
        <div v-if="chosenRegion" class="location-dropdown__clear cursor-pointer" @click="clear">
          <img src="@/assets/images/svg/close.svg" alt="закрыть">
        </div>
        <button
          class="btn location-modal__btn btn-green"
          type="button"
          :disabled="!chosenRegion"
          @click.stop="submitRegion">
          Сохранить
          <img src="@/assets/images/svg/arrow-right.svg" class="location-modal__icon" alt="">
        </button>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import SessionHelper from '@/helpers/SessionHelper';
import { mapActions } from 'vuex';

export default {
  name: 'LocationChoice',
  data() {
    return {
      chosenRegion: '',
      debounce: 2000,
      lastInput: 0,
      regions: []
    }
  },
  mounted() {
    this.$nextTick(() => this.$refs.input.focus());
  },
  methods: {
    clear() {
      this.chosenRegion = '';
    },
    closeModal() {
      this.$emit('close');
    },
    async getSuggests(query) {
      try {
        if (!query || query.length < 1) {
          throw new Error('Не введен запрос');
        }

        const geocode = await window.ymaps.geocode(query, { kind: 'locality', json: true, results: 10 });
        this.regions = geocode.GeoObjectCollection.featureMember.map(obj => {
          const bounds = [];
          const boundsObj = obj.GeoObject.boundedBy.Envelope;
          const lowerCorner = boundsObj.lowerCorner.split(' ').reverse();
          const upperCorner = boundsObj.upperCorner.split(' ').reverse();
          bounds.push([Number.parseFloat(lowerCorner[0]), Number.parseFloat(lowerCorner[1])]);
          bounds.push([Number.parseFloat(upperCorner[0]), Number.parseFloat(upperCorner[1])]);
          return {
            id: bounds[0].join('').replace(/\./g, ''),
            name: obj.GeoObject.name,
            desc: obj.GeoObject.description,
            bounds
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    },
    submitRegion() {
      const chosenRegion = this.regions.find(region => region.id === this.chosenRegion);
      if (chosenRegion) {
        const windowSize = [window.innerWidth, window.innerHeight];
        const location = window.ymaps.util.bounds.getCenterAndZoom(chosenRegion.bounds, windowSize);
        this.setLocation({
          coords: location.center,
          zoom: location.zoom
        });
        this.setUserLocationName(chosenRegion.name);
        SessionHelper.setLocationCookie(location.center);
      }
      this.closeModal();
    },
    ...mapActions('location', ['setLocation', 'setUserLocationName'])
  }
}
</script>

<style lang="scss">
  .location-modal {
    &__btn {
      width: 142px;
      margin-top: 5px;
      padding: 8px 16px 10px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:disabled {
        background-color: #E5E5E5;
        cursor: default;
      }
    }

    .el-dialog__headerbtn {
      width: 46px;
      height: 46px;
      top: 0;
      right: 0;
      background-image: url('../assets/images/svg/close.svg');
      background-position: center;
      background-size: 35px 35px;
      background-repeat: no-repeat;

      @media (max-width: 1023px) {
        width: 36px;
        height: 36px;
        background-size: 26px 26px;
      }

      i {
        display: none;
      }
    }

    &__icon {
      margin-top: 3px;
      width: 9px;
    }

    .el-dialog {
      max-width: 780px;
      //height: 414px;
      display: flex;
      flex-direction: column;
    }

    .el-dialog__header{
      padding: 40px 40px 20px;
    }

    .el-dialog__body {
      position: relative;
      flex-grow: 1;
      padding: 0 40px 40px;
      display: flex;
    }

    .el-dialog__body form {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .el-dialog__title {
      font-size: 26px;
      line-height: 1;
      font-weight: 700;
    }

    @media (max-width: 1023px) {
      padding-left: 10px;
      padding-right: 10px;

      .el-dialog {
        width: 100%;
        //height: 200px;
      }

      .el-dialog__header {
        padding: 30px 20px 20px;
      }

      .el-dialog__body {
        padding: 0 20px 30px;
      }

      .el-dialog__title {
        font-size: 24px;
      }

      &__btn {
        justify-content: center;
        width: 100%;
      }

      &__icon {
        display: none;
      }
    }
  }

  .location-dropdown {
    width: 100%;

    &__clear {
      position: absolute;
      top: 12px;
      right: 35px;
      width: 19px;
    }

    .el-input__inner {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #BFBFBF;
      padding-right: 20px;
    }

    .el-input__suffix {
      display: none;
    }

    @media (min-width: 768px) {

      .el-input__inner:placeholder-shown {
        border-bottom: 1px solid #BFBFBF;
      }

      .location-dropdown__clear {
        top: 15px;
        right: 50px;
      }
    }
  }
</style>
