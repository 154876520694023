<template>
  <div class="wrapper">
    <Sidebar :mapInstance="mapInstance"></Sidebar>
    <main class="main">
      <Map :getMap="initMap"></Map>
      <AuthReminder v-if="isAuth"></AuthReminder>
      <LayerSwitchMini></LayerSwitchMini>
      <ProductList></ProductList>
      <CookieAccept v-if="cookieVisible" @close="cookieVisible = false"></CookieAccept>
      <LayerSwitch v-if="switcherVisible" @close="switcherVisible = false"></LayerSwitch>
      <LocationConfirm
        v-if="locationConfirm.isVisible"
        :mode="locationConfirm.mode"
        @confirm="locationConfirm.isVisible = false"
        @adjust="toggleLocationPopup"></LocationConfirm>
      <LocationChoice v-if="isShowLocationSelectionPopup" @close="hideLocationSelectionPopup"></LocationChoice>
    </main>
  </div>
</template>

<script>
import SessionHelper from '@/helpers/SessionHelper';
import Sidebar from '@/components/Sidebar.vue';
import Map from '@/components/Map.vue';
import AuthReminder from '@/components/AuthReminder.vue';
import LayerSwitch from '@/components/LayerSwitch.vue';
import LayerSwitchMini from '@/components/LayerSwitchMini.vue';
import CookieAccept from '@/components/CookieAccept.vue';
import ProductList from '@/components/ProductList.vue';
import LocationConfirm from '@/components/LocationConfirm.vue';
import LocationChoice from '@/components/LocationChoice.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'LayoutSidebar',
    components: {
        Sidebar,
        Map,
        AuthReminder,
        LayerSwitch,
        LayerSwitchMini,
        CookieAccept,
        ProductList,
        LocationConfirm,
        LocationChoice
    },
    data() {
        return {
          mapInstance: null,
          cookieVisible: false,
          switcherVisible: false,
          locationChoiceVisible: false,
          locationConfirm: {
            isVisible: false,
            mode: ''
          }
        }
    },
    created() {
      const isFirstVisit = !SessionHelper.checkPrevSession();
      if (isFirstVisit) {
        this.cookieVisible = true;
        this.switcherVisible = true;
      } else if (!SessionHelper.checkTodaySession()) {
        SessionHelper.setSessionCookie();
      }
      this.getGeoLocation();
    },
    watch: {
      isShowLocationSelectionPopup(newVal) {
        if (newVal && this.locationConfirm.isVisible) {
          this.locationConfirm.isVisible = false;
        }
      }
    },
    methods: {
      initMap(map) {
        this.mapInstance = map
      },
      getGeoLocation() {
        if (this.setLocationCookie()) {
          return;
        }

        this.setLocationByGeo();
      },
      setLocationCookie() {
        const coords = SessionHelper.getLocationCookie();
        if (coords) {
          this.setLocation({ coords: JSON.parse(coords) });
          // this.$store.dispatch('setLocation', { coords: JSON.parse(coords) });
          this.confirmLocation();
          return true;
        }
        return false;
      },
      setLocationByGeo() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              position => {
              const coords = [position.coords.latitude, position.coords.longitude];
              SessionHelper.setLocationCookie(coords);
              this.setLocation({ coords });
              // this.$store.dispatch('setLocation', { coords });
              this.confirmLocation();
            },
              () => {
              // Вбиваем координаты Москвы и уточняем - поменять или оставить?
              this.setLocation({ coords: [55.7558, 37.6173] });
              // this.$store.dispatch('setLocation', { coords: [55.7558, 37.6173] });
              this.locationConfirm.mode = 'manual';
              this.locationConfirm.isVisible = true;
            }
          );
        }
      },
      confirmLocation() {
        this.locationConfirm.mode = 'confirm';
        this.locationConfirm.isVisible = false;
      },
      toggleLocationPopup() {
        this.$store.dispatch('toggleLocationChoice');
      },
      ...mapActions('location', ['setLocation', 'hideLocationSelectionPopup'])
    },
    computed: {
      isAuth() {
        return this.$store.getters['isAuth'];
      },
      isChoosingLocation() {
        return this.$store.getters['getChoosingLocationStatus'];
      },
      ...mapGetters('location', ['isShowLocationSelectionPopup'])
    }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  height: 100%;
}
.main {
  position: relative;
  height: 100%;
  width: calc(100% - 393px);
  margin-left: auto;
}

@media (max-width: 1023px) {
  .wrapper {
    flex-direction: column;
    overflow: hidden;
  }

  .main {
    width: 100vw;
    margin-left: 0;
  }
}
</style>
