import Vue from 'vue';
import Vuex from 'vuex';
import state from '@/store/state';
import actions from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import loader from './modules/loader';
import layer from './modules/layer';
import map from './modules/map';
import entity from './modules/entity';
import location from './modules/location';
import search from './modules/search';
import filter from './modules/filter';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
      entity,
      loader,
      layer,
      map,
      location,
      search,
      filter
    },
    state: {
      ...state
    },
    mutations: {
      ...mutations
    },
    actions: {
      ...actions
    },
    getters: {
      ...getters
    }
})
